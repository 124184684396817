/* --------------------------ANIMATIONS-------------------------- */
@keyframes rebota {
  from {
    top: 5%;
  }
  to {
    top: 12%;
  }
}

/* --------------------------Styles-------------------------- */

.conts_container {
  height: 100vh;
  width: 100%;
  .container {
    position: absolute;
  }
}

/* -------------------------Media querys-------------------------- */
/* portrait: alto mayor que el ancho (celu) */
@media all and (orientation: portrait) {
  .conts_container {
    .logo_container {
      display: none;
    }
    .text_container {
      /*     background-color: red; */
      width: 100%;
      height: 40%;
      img {
        position: absolute;
        height: 90%;
        left: 50%;
        top: 10%;
        transform: translate(-50%, 0);
        /*  background-color: yellow; */
      }
    }
    .circle_container {
      bottom: -10px;
      left: -10px;
      transform: rotate(180deg);
      height: 20%;
      img {
        height: 100%;
      }
    }
    .elements_container {
      height: 60%;
      width: 100%;
      bottom: 0;
      left: 0;
      .all_elements {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 3;
      }
      .popup {
        width: 13%;
        position: absolute;
        left: 15%;
        animation: rebota 1.5s alternate infinite ease-in;
        z-index: 2;
      }
    }
    .points_container {
      right: 0;
      width: 30%;
      img {
        width: 100%;
      }
    }
  }
}
/* landscape: el ancho de la pantalla es mayor al alto(pc,tablet) */
@media all and (orientation: landscape) {
  .conts_container {
    .logo_container {
      left: 5%;
      top: 5%;
    }
    .text_container {
      width: 40%;
      height: 80%;
      left: 10%;
      top: 10%;
      img {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;
      }
    }
    .circle_container {
      top: -10px;
      right: -10px;
      width: 10%;
      img {
        width: 100%;
      }
    }
    .elements_container {
      width: 40%;
      height: 80%;
      right: 10%;
      top: 10%;
      .all_elements {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 3;
      }
      .popup {
        width: 13%;
        position: absolute;
        left: 15%;
        animation: rebota 1.5s alternate infinite ease-in;
        z-index: 2;
      }
    }
    .points_container {
      height: 10%;
      bottom: 10px;
      img {
        height: 100%;
      }
    }
  }
}
